//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Spinner from "./Spinner.vue";
export default {
  name: "NewsletterSection",
  components: { Spinner },
  data() {
    return {
      url: process.env.api_url,
      n2gKey: process.env.n2gKey,
      emailRoute: process.env.email_route,
      emailFrom: process.env.email_from,
      emailTo: process.env.email_to_marketing,
      loading: false,
      success: false,
      error: false,
      form: {
        gender: "null",
        name: "",
        email: "",
        privacy: "",
        hp: false,
        hp2: null,
        hp3: null,
      },
    };
  },
  methods: {
    async formSubmit(e) {
      if (
        this.form.hp !== false ||
        this.form.hp2 !== null ||
        this.form.hp3 !== null
      ) {
        this.form = { gender: "null", name: "", email: "", privacy: "" };

        alert("Thank you for submission!");
      } else {
        try {
          // console.log("FORM: ", this.form);
          // activate loading animation during the POST request
          this.loading = true;

          // insert all values in the var newsletterData that will be sent to the newsletter portal
          var newsletterData = JSON.stringify({
            recipient: {
              email: this.form.email,
              Anrede: this.form.gender,
              first_name: "",
              last_name: this.form.name,
              created_at: new Date(),
            },
          });
          // axios request headers
          const newsletterHeaders = {
            "Content-Type": "application/json",
          };
          // insert all values in the var data that will be sent in the email
          var emailMessage = {
            subject: "Neue Newsletter Anmeldung",
            from: this.emailFrom,
            to: this.emailTo,
            html: `
            <div style='padding: 1rem'>
            <h1>Ein User hat sich für unseren Newsletter angemeldet</h1>
            <p></p>
            <p>Jemand hat sich für den Newsletter über die Newsletter-Sektion angemeldet. Wir warten noch auf die Zusage in der Bestätigungsemail:</p>
            <p></p>
            <p><strong>${this.form.gender && this.form.gender} ${
              this.form.name && this.form.name
            }</strong></p>
            <p></p>
            <p>Email: <strong>${this.form.email}</strong></p>
            <p></p>
            </div>
            `,
          };
          // axios request headers
          const emailHeaders = {
            "Content-Type": "application/json",
          };
          // axios POST request to register the newsletter
          await this.$axios
            .$post(
              `https://api.newsletter2go.com/forms/submit/${this.n2gKey}`,
              newsletterData,
              {
                headers: newsletterHeaders,
              }
            )
            .then(
              (result) => {
                // axios POST request to send the email to marketing department
                this.$axios
                  .$post(this.emailRoute, JSON.stringify(emailMessage), {
                    headers: emailHeaders,
                  })
                  .then(
                    (result) => {
                      this.loading = false;
                      this.success = true;
                      // console.log("SUCCESS! warning email", result.text);
                    },
                    (error) => {
                      this.loading = false;
                      this.error = true;
                      // console.log("FAILED warning email...", error.text);
                    }
                  );
              },
              (error) => {
                this.loading = false;
                this.error = true;
                // console.log("FAILED newsletter anmeldung...", error.text);
              }
            );
        } catch (error) {
          // console.log({ error });
        }
        // Reset form field
        this.form = { gender: "null", name: "", email: "", privacy: "" };
      }
    },
  },
};
