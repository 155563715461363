import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _07eddff7 = () => interopDefault(import('../pages/case-studies/index.vue' /* webpackChunkName: "pages/case-studies/index" */))
const _4d38a7dc = () => interopDefault(import('../pages/karriere/index.vue' /* webpackChunkName: "pages/karriere/index" */))
const _33a6beac = () => interopDefault(import('../pages/kontaktformular-vielen-dank/index.vue' /* webpackChunkName: "pages/kontaktformular-vielen-dank/index" */))
const _31541522 = () => interopDefault(import('../pages/presse/index.vue' /* webpackChunkName: "pages/presse/index" */))
const _90b0525e = () => interopDefault(import('../pages/preview/index.vue' /* webpackChunkName: "pages/preview/index" */))
const _1e6a8a3f = () => interopDefault(import('../pages/wissenswertes/index.vue' /* webpackChunkName: "pages/wissenswertes/index" */))
const _6a9db8ab = () => interopDefault(import('../pages/case-studies/preview/_slug/index.vue' /* webpackChunkName: "pages/case-studies/preview/_slug/index" */))
const _5e22b986 = () => interopDefault(import('../pages/karriere/preview/_slug/index.vue' /* webpackChunkName: "pages/karriere/preview/_slug/index" */))
const _5296dc23 = () => interopDefault(import('../pages/presse/preview/_slug/index.vue' /* webpackChunkName: "pages/presse/preview/_slug/index" */))
const _725ec4f3 = () => interopDefault(import('../pages/wissenswertes/preview/_slug/index.vue' /* webpackChunkName: "pages/wissenswertes/preview/_slug/index" */))
const _407fc5f2 = () => interopDefault(import('../pages/case-studies/_slug/index.vue' /* webpackChunkName: "pages/case-studies/_slug/index" */))
const _71b4d1cd = () => interopDefault(import('../pages/karriere/_slug/index.vue' /* webpackChunkName: "pages/karriere/_slug/index" */))
const _bea03d2c = () => interopDefault(import('../pages/presse/_slug/index.vue' /* webpackChunkName: "pages/presse/_slug/index" */))
const _6b109d68 = () => interopDefault(import('../pages/preview/_slug/index.vue' /* webpackChunkName: "pages/preview/_slug/index" */))
const _4e159a3a = () => interopDefault(import('../pages/wissenswertes/_slug/index.vue' /* webpackChunkName: "pages/wissenswertes/_slug/index" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _f6ee20da = () => interopDefault(import('../pages/_slug/index.vue' /* webpackChunkName: "pages/_slug/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/case-studies",
    component: _07eddff7,
    name: "case-studies"
  }, {
    path: "/karriere",
    component: _4d38a7dc,
    name: "karriere"
  }, {
    path: "/kontaktformular-vielen-dank",
    component: _33a6beac,
    name: "kontaktformular-vielen-dank"
  }, {
    path: "/presse",
    component: _31541522,
    name: "presse"
  }, {
    path: "/preview",
    component: _90b0525e,
    name: "preview"
  }, {
    path: "/wissenswertes",
    component: _1e6a8a3f,
    name: "wissenswertes"
  }, {
    path: "/case-studies/preview/:slug",
    component: _6a9db8ab,
    name: "case-studies-preview-slug"
  }, {
    path: "/karriere/preview/:slug",
    component: _5e22b986,
    name: "karriere-preview-slug"
  }, {
    path: "/presse/preview/:slug",
    component: _5296dc23,
    name: "presse-preview-slug"
  }, {
    path: "/wissenswertes/preview/:slug",
    component: _725ec4f3,
    name: "wissenswertes-preview-slug"
  }, {
    path: "/case-studies/:slug",
    component: _407fc5f2,
    name: "case-studies-slug"
  }, {
    path: "/karriere/:slug",
    component: _71b4d1cd,
    name: "karriere-slug"
  }, {
    path: "/presse/:slug",
    component: _bea03d2c,
    name: "presse-slug"
  }, {
    path: "/preview/:slug",
    component: _6b109d68,
    name: "preview-slug"
  }, {
    path: "/wissenswertes/:slug",
    component: _4e159a3a,
    name: "wissenswertes-slug"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/:slug",
    component: _f6ee20da,
    name: "slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
